<template>
  <h1 class="v-page-title">
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'VPageTitle'
}
</script>
